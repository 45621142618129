import { Component, Prop } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'

@Component
export default class WebsiteHeader extends BaseMixin {
  @Prop({ type: Array }) readonly menuItems!: []
  @Prop({ type: Boolean }) isMenuOpen!: boolean

  toggleMenu () {
    this.$emit('toggleMenu')
  }
}
